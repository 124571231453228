import React from "react";

import { FormattedMessage } from 'react-intl';

import * as locales from "./langs/locales.js";
import codes from "./langs/codes.js";

export const alpha3TToAlpha2 = (code) => {
  return codes.alpha3T[code];
};

export const alpha3BToAlpha2 = (code) => {
  return codes.alpha3B[code];
};

export const alpha2ToAlpha3T = (code) => {
  return codes.alpha2[code];
};

export const alpha2ToAlpha3B = (code) => {
  return codes.invertedAlpha3B[code];
};

export const toAlpha3T = (code) => {
  if (typeof code === "string") {
    if(code.length === 2) {
      return alpha2ToAlpha3T(code.toLowerCase());
    }
    if (code.length === 3) {
      if ( codes.alpha3T[code.toLowerCase()] ) {
        return code.toLowerCase();
      }
      if ( alpha3BToAlpha2(code.toLowerCase()) ) {
        return alpha2ToAlpha3T(alpha3BToAlpha2(code.toLowerCase()));
      }
    }
  }
  return void(0);
}

export const toAlpha3B = (code) => {
  if (typeof code === "string") {
    if(code.length === 2) {
      return alpha2ToAlpha3B(code.toLowerCase());
    }
    if (code.length === 3) {
      if ( codes.alpha3B[code.toLowerCase()] ) {
        return code.toLowerCase();
      }
      if ( codes.alpha3T[code.toLowerCase()] ) {
        return alpha2ToAlpha3B(alpha3TToAlpha2(code.toLowerCase()));
      }
    }
  }
  return void(0);
};

export const toAlpha2 = (code) => {
  if (typeof code === "string") {
    if (code.length === 2) {
      return code.toLowerCase();
    }
    if(code.length === 3) {
      if ( codes.alpha3B[code.toLowerCase()] ) {
        return alpha3BToAlpha2(code.toLowerCase());
      }
      if ( codes.alpha3T[code.toLowerCase()] ) {
        return alpha3TToAlpha2(code.toLowerCase());
      }
    }
  }
  return void(0);
};

export const getAlpha2Code = (name, lang) => {
  try {
    var p, codenames = locales[lang.toLowerCase()];
    for (p in codenames) {
      if (codenames.hasOwnProperty(p)) {
        if (codenames[p].toLowerCase() === name.toLowerCase()) {
          return p;
        }
      }
    }
    return void(0);
  } catch (err) {
    return void(0);
  }
};

export const getAlpha2Codes = () => {
  return codes.alpha2;
};

export const getAlpha3TCode = (name, lang) => {
  var alpha2 = getAlpha2Code(name, lang);
  if (alpha2) {
    return toAlpha3T(alpha2);
  } else {
    return void(0);
  }
};

export const getAlpha3BCode = (name, lang) => {
  var alpha2 = getAlpha2Code(name, lang);
  if (alpha2) {
    return toAlpha3B(alpha2);
  } else {
    return void(0);
  }
};

export const getAlpha3TCodes = () => {
  return codes.alpha3T;
};

export const getAlpha3BCodes = () => {
  return codes.alpha3B;
};

export const isValid = (code) => {
  return !!toAlpha3T(code);
};

export const formatLocaleMessage = (lang, id, message, ref) => (
  <FormattedMessage id={id} defaultMessage={message}>
    {(msg) => (<span ref={ref} id={id} lang={lang} style={{display: "none"}}>{msg}</span>)}
  </FormattedMessage>
);

