import { apiServerBaseUrl, apiServerUrlSuffix } from "./config";

import client from "./request-client";

class GraphQLRequest {
  constructor(builder) {
    this.url = apiServerBaseUrl + apiServerUrlSuffix;
    this.method = 'post';
    this.queryTemplate = builder.queryTemplate;
    this.queryParameters = builder.queryParameters;
    this.__getQueryName__ = () => { return builder.queryName; };
  }
  get queryName() { return this.__getQueryName__(); }
  async submitRequest(onResponse) {
    let queryStatement = this.queryTemplate.apply(null, this.queryParameters);

    let response = await client.post('/api/graphql', {
        "query": queryStatement,
        "variables": {},
        "operationName": null
    });
    
    if (typeof onResponse === "function") onResponse(response);
  }
}

class RequestBuilder {
  setQueryName(name) {
    this.queryName = name;
    return this;
  }
  setQueryTemplate(template) {
    this.queryTemplate = template;
    return this;
  }
  setQueryParameters(parameters) {
    this.queryParameters = parameters;
    return this;
  }
  setQueryParameter(parameter) {
    (this.queryParameters || (this.queryParameters = [])).concat([parameter]);
    return this;
  }
  prepareQuery() {
    return new GraphQLRequest(this);
  }
}

export default RequestBuilder;

