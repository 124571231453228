import RequestBuilder from "../request-builder";

//var axios = require('axios');
//var MockAdapter = require('axios-mock-adapter');
//var normalAxios = axios.create();
//var mockAxios = axios.create();

//var mock = new MockAdapter(mockAxios);

//mock.onGet('/randomstations').reply(200, require('./mock/randomstations'));
//mock.onGet('/randomusers').reply(200, require('./mock/randomusers'));

//export default mockAxios;

import * as requests from "../request-templates";

const queriesMap = new Map([
  ["associatemeasure", {name: "associateMeasure", template: requests["AssociateMeasureTemplate"], parameters: requests["AssociateMeasureParameters"]}],
  ["alloptions", {name: "allOptions", template: requests["AllOptionsQueryTemplate"], parameters: requests["AllOptionsQueryParameters"]}],
  ["allstations", {name: "allStations", template: requests["AllStationsQueryTemplate"], parameters: requests["AllStationsQueryParameters"]}],
  ["allmeasures", {name: "allMeasures", template: requests["AllMeasuresQueryTemplate"], parameters: requests["AllMeasuresQueryParameters"]}],
  ["allmeasurescategory", {name: "allMeasuresCategory", template: requests["AllMeasuresCategoryQueryTemplate"], parameters: requests["AllMeasuresQueryParameters"]}],
  ["allusers", {name: "allUsers", template: requests["AllUsersQueryTemplate"], parameters: requests["AllUsersQueryParameters"]}],
  ["allroles", {name: "allRoles", template: requests["AllRolesQueryTemplate"], parameters: requests["AllRolesQueryParameters"]}],
  ["allcategories", {name: "allCategories", template: requests["AllCategoriesQueryTemplate"], parameters: requests["AllCategoriesQueryParameters"]}],
  ["user", {name: "user", template: requests["UserInfoQueryTemplate"], parameters: requests["UserInfoQueryParameters"]}],
  ["connection", {name: "connection", template: requests["UserConnectionQueryTemplate"], parameters: requests["UserConnectionQueryParameters"]}],
  ["userbytoken", {name: "userByToken", template: requests["UserByTokenQueryTemplate"], parameters: requests["UserByTokenQueryParameters"]}],
  ["users", {name: "users", template: requests["UsersQueryTemplate"], parameters: requests["UsersQueryParameters"]}],
  ["assignroles", {name: "assignRoles", template: requests["AssignRolesTemplate"], parameters: requests["AssignRolesParameters"]}],
  ["rolesbyuserid", {name: "rolesByUserId", template: requests["RolesByUserIdQueryTemplate"], parameters: requests["RolesByUserIdParameters"]}],
  ["stations", {name: "stations", template: requests["StationsQueryTemplate"], parameters: requests["StationsQueryParameters"]}],
  ["stationsbyuserid", {"name": "stationsByUserId", template: requests["StationsByUserIdQueryTemplate"], parameters: requests["StationsByUserIdQueryParameters"]}],
  ["measuresbyuserid", {"name": "measuresByUserId", template: requests["MeasuresByUserIdQueryTemplate"], parameters: requests["MeasuresByUserIdQueryParameters"]}],
  ["measuresbystationid", {"name": "measuresByStationId", template: requests["MeasuresByStationIdQueryTemplate"], parameters: requests["MeasuresByStationIdQueryParameters"]}],
  ["backendstatus", {"name": "backendStatus", template: requests["BackendStatusQueryTemplate"], parameters: requests["BackendStatusQueryParameters"]}],
  ["stationstatus", {"name": "stationStatus", template: requests["StationStatusQueryTemplate"], parameters: requests["StationStatusQueryParameters"]}],
  ["allstationsstatus", {"name": "allStationsStatus", template: requests["AllStationsStatusQueryTemplate"], parameters: requests["AllStationsStatusQueryParameters"]}],
  ["categories", {name: "categories", template: requests["CategoriesQueryTemplate"], parameters: requests["CategoriesQueryParameters"]}],
  ["createuser", {name: "createUser", template: requests["CreateUserTemplate"], parameters: requests["CreateUserParameters"]}],
  ["destroyuser", {name: "destroyUser", template: requests["DestroyUserTemplate"], parameters: requests["DestroyUserParameters"]}],
  ["updateuser", {name: "updateUser", template: requests["UpdateUserTemplate"], parameters: requests["UpdateUserParameters"]}],
  ["createftpconnection", {name: "createFTPConnection", template: requests["CreateFTPConnectionTemplate"], parameters: requests["CreateFTPConnectionParameters"]}],
  ["assignftpconnection", {name: "assignFTPConnection", template: requests["AssignFTPConnectionTemplate"], parameters: requests["AssignFTPConnectionParameters"]}],
  ["assignmeasure", {name: "assignMeasure", template: requests["AssignMeasureTemplate"], parameters: requests["AssignMeasureParameters"]}],
  ["updatemeasure", {name: "updateMeasure", template: requests["UpdateMeasureTemplate"], parameters: requests["UpdateMeasureParameters"]}],
  ["destroymeasure", {name: "destroyMeasure", template: requests["DestroyMeasureTemplate"], parameters: requests["DestroyMeasureParameters"]}],
  ["assignwidget", {name: "assignWidget", template: requests["AssignWidgetTemplate"], parameters: requests["AssignWidgetParameters"]}],
  ["updatewidget", {name: "updateWidget", template: requests["UpdateWidgetTemplate"], parameters: requests["UpdateWidgetParameters"]}],
  ["destroywidget", {name: "destroyWidget", template: requests["DestroyWidgetTemplate"], parameters: requests["DestroyWidgetParameters"]}],
  ["createdashboard", {name: "createDashboard", template: requests["CreateDashboardTemplate"], parameters: requests["CreateDashboardParameters"]}],
  ["destroydashboard", {name: "destroyDashboard", template: requests["DestroyDashboardTemplate"], parameters: requests["DestroyDashboardParameters"]}],
  ["updatedashboard", {name: "updateDashboard", template: requests["UpdateDashboardTemplate"], parameters: requests["UpdateDashboardParameters"]}],
  ["updatepassword", {name: "updatePassword", template: requests["UpdatePasswordTemplate"], parameters: requests["UpdatePasswordParameters"]}],
  ["reportenquiry", {name: "ReportEnquiry", template: requests["ReportQueryTemplate"], parameters: requests["ReportQueryParameters"]}]  
]);

const defaults = new Map();

const doRequest = (path, options) => {
  const { params, responseType } = options;
  if (path.lastIndexOf('/', 0) === 0) {
    path = path.substr(1);
  }
  path = path.toLowerCase();
  if (!queriesMap.has(path)) {
    throw new Error(`${path} request query path is unsupported`);
  }
  const qry = queriesMap.get(path);
  if ( typeof params !== "undefined" ) {
    Object.keys(params).forEach(key => {
	  if (Array.isArray(qry.parameters) && qry.parameters.length > 0) {
        if ( key in qry.parameters[0] ) {
          let pkey = `${path}:${key}`;
          if (!defaults.has(pkey))
            defaults.set(pkey, qry.parameters[0][key]);
          qry.parameters[0][key] = params[key];
	    }
	  }
    });
  }
  if ( responseType ) {
    // nothing implemented yet..
  }
  return new Promise((resolve, reject) => {
    let rb = new RequestBuilder().setQueryName(qry.name).setQueryTemplate(qry.template).setQueryParameters(qry.parameters).prepareQuery();
    rb.submitRequest((response) => {
      if (response && response.status === 200) {
        if (response.data && ("data" in response.data)) {
          //resolve({data: response.data.data[qry.name]});
          if (response.data.errors && Array.isArray(response.data.errors)) {
            reject({ status: 200, data: null, errors: response.data.errors });
          } else {
            resolve({status: 200, data: response.data.data[qry.name]});
          }
        }
      } else {
        reject({ status: !!response ? response.status : 0, response });
	  }

      for (let key of defaults.keys()) {
        if (!options.volatile) break;
        const pathRE = new RegExp('^' + path + ':');
        const qkey = key.replace(pathRE, '');
        if (pathRE.test(key))
          qry.parameters[0][qkey] = defaults.get(key);
      }
    });
  });
};

const post = (path, options) => {
  options = options || {};
  options.method = 'POST';
  return doRequest(path, options);
};

const get = (path, options) => {
  options = options || {};
  options.method = 'GET';
  return doRequest(path, options);
};

export default { get, post };