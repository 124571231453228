const template = (strings, ...keys) => {
  return (function(...values) {
    var dict = values[values.length - 1] || {};
    var result = [strings[0]];
    keys.forEach(function(key, i) {
      var value = Number.isInteger(key) ? values[key] : dict[key];
      value = (value === true || value === false) ? Boolean(value).toString() : (Array.isArray(value)) ? JSON.stringify(value) : value;
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  });
};

const UserInfoQueryParameters = [{'id': 0}];
const UserByTokenQueryParameters = [{'token': ''}];
const UserConnectionQueryParameters = [{'user_id': 0}];
const CategoriesQueryParameters = [{'sortField': 'category_name', 'sortDirection': 'ASC', 'firstPage': 1, 'categoryNameStartsWith': '',
'categoryNameEndsWith': '', 'categoryDescriptionStartsWith': '', 'categoryDescriptionEndsWith': '', 'localeEq': 'en_US'}];
const UsersQueryParameters = [{'sortField': 'username', 'sortDirection': 'ASC', 'firstPage': 1, 'startPage': 1, 'usernameStartsWith': '', 'usernameEndsWith': ''}];
const RolesByUserIdParameters = [{'id': 0}];
const StationsQueryParameters = [{'sortField': 'name', 'sortDirection': 'ASC', 'firstPage': 1, 'nameStartsWith': '', 'nameEndsWith': ''}];
const StationStatusQueryParameters = [{'id': 0}];
const AllStationsStatusQueryParameters = [];
const AllOptionsQueryParameters = [{'sortField': 'name', 'sortDirection': 'ASC'}];
const AllMeasuresCategoryQueryParameters = [{'sortField': 'name', 'sortDirection': 'ASC'}];
const BackendStatusQueryParameters = [{'backendName': 'REDIS'}];
const StationsByUserIdQueryParameters = [{'id': 0}];
const MeasuresByUserIdQueryParameters = [{'id': 0}];
const MeasuresByStationIdQueryParameters = [{'id': 0}];
const WidgetsQueryParameters = [{'dashboardId': 0}];
const WindDirectionQueryParameters = [{'id': 0}];
const WindRoseQueryParameters = [{'id': 0}];
const PlotQueryParameters = [{'id': 0}];
const GaugeQueryParameters = [{'id': 0}];
const LastValueQueryParameters = [{'id': 0}];
const SeriesQueryParameters = [{'id': 0}];
const TableQueryParameters = [{'id': 0}];
const ColumnQueryParameters = [{'id': 0}];
const HistogramQueryParameters = [{'id': 0, 'aggregationCriteria': 'CMM'}];
const ReportQueryParameters = [{'filters': [], 'dtStart': '', 'dtEnd': ''}];
const AllMeasuresQueryParameters = [{'sortField': 'name', 'sortDirection': 'DESC'}];
const AllDashboardsQueryParameters = [{'sortField': 'id', 'sortDirection': 'ASC'}];
const AllCategoriesQueryParameters = [{'sortField': 'name', 'sortDirection': 'ASC'}];
const AllWidgetsQueryParameters = [{'sortField': 'widget_index', 'sortDirection': 'ASC'}];
const AllStationsQueryParameters = [{'sortField': 'name', 'sortDirection': 'ASC'}];
const AllRolesQueryParameters = [{'sortField': 'name', 'sortDirection': 'ASC'}];
const AllUsersQueryParameters = [{'sortField': 'name', 'sortDirection': 'ASC'}];
const DestroyStationParameters = [{'id': 0}];
const CreateStationParameters = [{'name': '', 'timezone': ''}];
const AssignStationParameters = [{'stationId': 0, 'userId': 0}];
const UpdateStationParameters = [{'id': 0, 'name': '', 'timezone': ''}];
const DestroyUserParameters = [{'id': 0}];
const UpdateUserParameters = [{'id': 0, 'username': '', 'password': '', 'email': '', 'firstName': '', 'lastName': '', 'timezone': '', 'locale': '', 'hcLocale': '', 'numericFormat': '', 'datetimeFormat': '', 'ftpUsername': '', 'ftpPassword': '', 'ftpDirectory': '', 'ftpULBandwidth': 0, 'ftpDLBandwidth': 0, 'ftpQuotaSize': 0, 'ftpQuotaFiles': 0, 'ftpIPAccess': '', 'ftpStatus': true, 'defaultDashboard': false}];
const CreateUserParameters = [{'username': '', 'password': '', 'email': '', 'firstName': '', 'lastName': '', 'timezone': '', 'locale': '', 'hcLocale': '', 'numericFormat': '', 'datetimeFormat': '', 'ftpUsername': '', 'ftpPassword': '', 'ftpDirectory': '', 'ftpULBandwidth': 0, 'ftpDLBandwidth': 0, 'ftpQuotaSize': 0, 'ftpQuotaFiles': 0, 'ftpIPAccess': '', 'ftpStatus': true, 'defaultDashboard': false}];
const UpdatePasswordParameters = [{'id': 0, 'currentPassword': '', 'newPassword': '', 'confirmPassword': ''}];
const CreateFTPConnectionParameters = [{'username': '', 'password': '', 'dir': '', 'ulbandwidth': 0, 'dlbandwidth': 0, 'ipaccess': '', 'quotasize': 0, 'quotafiles': 0}];
const AssignFTPConnectionParameters = [{'connectionId': 0, 'userId': 0}];
const AssignMeasureParameters = [{'id': 0, 'stationId': 0, 'stationName': '', 'name': '', 'uom': '', 'conversionFactor': 0, 'decimals': 0, 'category': ''}];
const UpdateMeasureParameters = [{'id': 0, 'stationId': 0, 'stationName': '', 'name': '', 'uom': '', 'conversionFactor': 0, 'decimals': 0, 'category': ''}];
const AssociateMeasureParameters = [{'id': 0, 'stationId': 0, 'measureId': 0, 'association': ''}];
const DestroyMeasureParameters = [{'id': 0, 'stationId': 0}];
const AssignWidgetParameters = [{'dashboardId': 0, 'stationId': 0, 'measureId': 0, 'chartType': '', 'category': '', 'name': '', 'description': ''}];
const UpdateWidgetParameters = [{'dashboardId': 0, 'stationId': 0, 'measureId': 0, 'chartType': '', 'category': '', 'name': '', 'description': ''}];
const DestroyWidgetParameters = [{'id': 0}];
const CreateDashboardParameters = [{'name': '', 'description': '', 'isSeeded': false}];
const DestroyDashboardParameters = [{'id': 0}];
const UpdateDashboardParameters = [{'id': 0, 'name': '', 'description': '', 'isSeeded': false}];
const AssignRolesParameters = [{'roleIds': [], 'userId': 0}];

const ReportQueryTemplate = template`{
  ReportEnquiry(filters: ${'filters'}, dates: {
    start: "${'dtStart'}"
    end: "${'dtEnd'}"
  }) {
    station_id
    measure_id
    start
    end
	uom
    name
    type
    data
	categories
    yAxis
    yAxes { title format }
  }
}`;

const RolesByUserIdQueryTemplate = template`{
  rolesByUserId(id: ${'id'}) {
    id
    username
    email
    first_name
    last_name
    role_name
    role_label
  }
}`;

const UserConnectionQueryTemplate = template`{
  connection(user_id: ${'user_id'}) {
    id
    user_id
    username
    password
    credentials
    dir
    ulbandwidth
    dlbandwidth
    ipaddress
    ipaccess
    quotasize
    quotafiles
  }
}`;

const UserInfoQueryTemplate = template`{
  user(id: ${'id'}) {
    id
    username
    timezone
    locale
    hc_locale
    datetime_format
    numeric_format
    first_name
    last_name
    email
  }
}`;

const UserByTokenQueryTemplate = template`{
  userByToken(token: "${'token'}") {
    id
    username
    timezone
    locale
    datetime_format
    numeric_format
    first_name
    last_name
    email
    is_admin
    plan_expiration
    plan_type
  }
}`;

const BackendStatusQueryTemplate = template`{
  backendStatus(backendName: "${'backendName'}") {
    status
    last_update_date
  }
}`;

const AllStationsStatusQueryTemplate = template`{
  allStationsStatus {
    status
  }
}`;

const StationStatusQueryTemplate = template`{
  stationStatus(id: ${'id'}) {
    status
    last_update_date
  }
}`;

const MeasuresByUserIdQueryTemplate = template`{
  measuresByUserId(id: ${'id'}) {
    id
    station_id
    measure_id
    station_name
    measure_name
    category_name
    conversion_factor
    decimals
    uom
    enabled
  }
}`;

const MeasuresByStationIdQueryTemplate = template`{
  measuresByStationId(id: ${'id'}) {
    id
    measure_id
    measure_name
    conversion_factor
    decimals
  }
}`;

const StationsByUserIdQueryTemplate = template`{
  stationsByUserId(id: ${'id'}) {
    id
    username
    email
    first_name
    last_name
    station_name
    station_id
    timezone
    interval
    sensor_categories
    location
  }
}`;

const CategoriesQueryTemplate = template`{
  categories(
    first: ${'firstPage'}
    filter: {
      locale_eq: "${'localeEq'}"
      category_name_starts_with: "${'categoryNameStartsWith'}"
      category_name_ends_with: "${'categoryNameEndsWith'}"
      category_description_starts_with: "${'categoryDescriptionStartsWith'}"
      category_description_ends_with: "${'categoryDescriptionEndsWith'}"
    }
    orderBy: [
      {
        field: "${'sortField'}"
        order: ${'sortDirection'}
      }
    ]
  ) {
    data {
      id
      category_name
      category_description
    }
    paginatorInfo {
      currentPage
      lastPage
    }
  }
}`;

const StationsQueryTemplate = template`{
  stations(
    first: ${'firstPage'}
    filter: {
      station_name_starts_with: "${'nameStartsWith'}"
      station_name_ends_with: "${'nameEndsWith'}"
    }
    orderBy: [
      {
        field: "${'sortField'}"
        order: ${'sortDirection'}
      }
    ]
  ) {
    data {
      id
      name
      timezone
      interval
    }
    paginatorInfo {
      currentPage
      lastPage
    }
  }
}`;

const UsersQueryTemplate = template`{
  users(
    first: ${'firstPage'}
    page: ${'startPage'}
    filter: {
      username_starts_with: "${'usernameStartsWith'}"
      username_ends_with: "${'usernameEndsWith'}"
    }
    orderBy: [
      {
        field: "${'sortField'}"
        order: ${'sortDirection'}
      }
    ]
  ) {
    data {
      id
      username
      ftp_username
      ftp_directory
      ftp_ulbandwidth
      ftp_dlbandwidth
      ftp_quotasize
      ftp_quotafiles
      ftp_ipaccess
      email
      first_name
      last_name
      timezone
      locale
      hc_locale
      datetime_format
      numeric_format
	  default_dashboard_id
    }
    paginatorInfo {
      currentPage
      lastPage
    }
  }
}`;

const WidgetsQueryTemplate = template`{
  WidgetsEnquiry(dashboardId: ${'dashboardId'}) {
    widget_id
    dashboard_id
    station_id
    measure_id
    chart_type
    enquiry_name
    name
    description
  }
}`;

const WindRoseQueryTemplate = template`{
  WindRoseEnquiry(id: ${'id'}) {
    name
    pointPadding
    groupPadding
    data
    _colorIndex
  }
}`;

const WindDirectionQueryTemplate = template`{
  WindDirectionEnquiry(id: ${'id'}) {
    measurement_date
    measurement_data
  }
}`;

const PlotQueryTemplate = template`{
  PlotEnquiry(id: ${'id'}) {
    measurement_date
    measurement_data
  }
}`;

const GaugeQueryTemplate = template`{
  GaugeEnquiry(id: ${'id'}) {
    measure_id
    data_low
    data_high
    data_current
  }
}`;

const LastValueQueryTemplate = template`{
  LastValueEnquiry(id: ${'id'}) {
     measurement_date
     measurement_data
  }
}`;

const SeriesQueryTemplate = template`{
  SeriesEnquiry(id: ${'id'}) {
    measurement_date
    measurement_data
  }
}`;

const TableQueryTemplate = template`{
  TabularEnquiry(id: ${'id'}) {
    measurement_date
    measurement_data
  }
}`;

const ColumnQueryTemplate = template`{
  ColumnEnquiry(id: ${'id'}) {
    measurement_date
    measurement_data
  }
}`;

const HistogramQueryTemplate = template`{
  HistogramEnquiry(id: ${'id'}, aggregationCriteria: ${'aggregationCriteria'}) {
    measure_id
    data_current
    data_low
    data_high
  }
}`;

const AllOptionsQueryTemplate = template`{
   allOptions(orderBy: [{
     field: "${'sortField'}"
     order: ${'sortDirection'}
   }]) {
     ftp_basepath
     user_timezone
     user_id
     user_roles
  }
}`;

const AllMeasuresCategoryQueryTemplate = template`{
  allMeasuresCategory(orderBy: [{
    field: "${'sortField'}"
    order: ${'sortDirection'}
  }]) {
    id
    station_id
    station_name
    category_name
    category_description
    measures
  }
}`;

const AllMeasuresQueryTemplate = template`{
  allMeasures(orderBy: [{
    field: "${'sortField'}"
     order: ${'sortDirection'}
  }]) {
    id
    station_id
    name
    uom
    conversion_factor
    decimals
    station_name
    category_id
    category_name
    category_description
  }
}`;

const AllDashboardsQueryTemplate = template`{
  allDashboards(orderBy: [{
    field: "${'sortField'}"
    order: ${'sortDirection'}
  }]) {
    is_seeded
    name
    description
    id
  }
}`;

const AllCategoriesQueryTemplate = template`{
  allCategories(orderBy: [{
    field: "${'sortField'}"
    order: ${'sortDirection'}
  }]) {
    name
    description
    value
    id
  }
}`;

const AllWidgetsQueryTemplate = template`{
  allWidgets(orderBy: [{
    field: "${'sortField'}"
    order: ${'sortDirection'}
  }]) {
    widget_id
    dashboard_id
    station_id
    measure_id
    category_id
    widget_name
    header_title
    header_icon
    names
    categories
    title
    y_title
    y_min
    y_max
    uom
    conversion_factor
    decimals
  }
}`;

const AllStationsQueryTemplate = template`{
  allStations(orderBy: [{
    field: "${'sortField'}"
    order: ${'sortDirection'}
  }]) {
    id
    user_id
    name
    username
    timezone
    interval
    location
    sensor_categories
  }
}`;

const AllRolesQueryTemplate = template`{
  allRoles(orderBy: [{
    field: "${'sortField'}"
    order: ${'sortDirection'}
  }]) {
    id
    name
    label
	is_seeded
  }
}`;

const AllUsersQueryTemplate = template`{
  allUsers(orderBy: [{
    field: "${'sortField'}"
    order: ${'sortDirection'}
  }]) {
    id
    username
    timezone
    password
    email
    first_name
    last_name
    locale
    hc_locale
    datetime_format
    numeric_format
    ftp_username
    ftp_password
    ftp_directory
    ftp_basepath
    ftp_ulbandwidth
    ftp_dlbandwidth
    ftp_quotasize
    ftp_quotafiles
    ftp_ipaccess
  }
}`;

const DestroyWidgetTemplate = template`mutation {
  destroyWidget(id: ${'id'}) {
    id
    dashboard_id
    station_id
    measure_id
    category_id
    master_categories
    category_data
    aggregation_type_id
    measure_index
    widget_index
    name
    description
  }
}`;

const AssignWidgetTemplate = template`mutation {
  assignWidget(dashboard_id: ${'dashboardId'}, station_id: ${'stationId'}, measure_id: ${'measureId'}, chart_type: "${'chartType'}", category: "${'category'}", name: "${'name'}", description: "${'description'}") {
    id
    dashboard_id
    station_id
    measure_id
    category_id
    master_categories
    category_data
    aggregation_type_id
    measure_index
    widget_index
    name
    description
  }
}`;

const UpdateWidgetTemplate = template`mutation {
  updateWidget(dashboard_id: ${'dashboardId'}, station_id: ${'stationId'}, measure_id: ${'measureId'}, chart_type: "${'chartType'}", category: "${'category'}", name: "${'name'}", description: "${'description'}") {
    id
    dashboard_id
    station_id
    measure_id
    category_id
    master_categories
    category_data
    aggregation_type_id
    measure_index
    widget_index
    name
    description
  }
}`;

const AssignStationTemplate = template`mutation {
  assignStation(station_id: ${'stationId'}, user_id: ${'userId'}) {
    id
    username
    timezone
    password
    email
    first_name
    last_name
    locale
    datetime_format
    numeric_format
  }
}`;

const DestroyStationTemplate = template`mutation {
  destroyStation(id: ${'id'}) {
    id
    name
    timezone
  }
}`;

const CreateStationTemplate = template`mutation {
  createStation(name: "${'name'}", timezone: "${'timezone'}") {
    id
    name
    timezone
    interval
  }
}`;

const UpdateStationTemplate = template`mutation {
  updateStation(id: ${'id'}, name: "${'name'}", timezone: "${'timezone'}") {
    id
    name
    timezone
    inteval
  }
}`;

const CreateUserTemplate = template`mutation {
  createUser(username: "${'username'}", password: "${'password'}", email: "${'email'}",
    firstName: "${'firstName'}", lastName: "${'lastName'}", timezone: "${'timezone'}",
    locale: "${'locale'}", hcLocale: "${'hcLocale'}", numericFormat: "${'numericFormat'}", datetimeFormat: "${'datetimeFormat'}",
    ftpUsername: "${'ftpUsername'}", ftpPassword: "${'ftpPassword'}", ftpDirectory: "${'ftpDirectory'}",
    ftpULBandwidth: ${'ftpULBandwidth'}, ftpDLBandwidth: ${'ftpDLBandwidth'},
    ftpQuotaFiles: ${'ftpQuotaFiles'}, ftpQuotaSize: ${'ftpQuotaSize'},
    ftpIPAccess: "${'ftpIPAccess'}", ftpStatus: ${'ftpStatus'}, defaultDashboard: ${'defaultDashboard'}) {
    id
    username
    first_name
    last_name
    email
  }
}`;

const UpdatePasswordTemplate = template`mutation {
  updatePassword(id: ${'id'}, currentPassword: "${'currentPassword'}", newPassword: "${'newPassword'}", confirmPassword: "${'confirmPassword'}") {
    id
    username
    timezone
    locale
    hc_locale
    datetime_format
    numeric_format
    first_name
    last_name
    email
  }
}`;

const UpdateUserTemplate = template`mutation {
  updateUser(id: ${'id'}, username: "${'username'}", password: "${'password'}", email: "${'email'}",
    firstName: "${'firstName'}", lastName: "${'lastName'}", timezone: "${'timezone'}",
    locale: "${'locale'}", hcLocale: "${'hcLocale'}", numericFormat: "${'numericFormat'}", datetimeFormat: "${'datetimeFormat'}",
    ftpUsername: "${'ftpUsername'}", ftpPassword: "${'ftpPassword'}", ftpDirectory: "${'ftpDirectory'}",
    ftpULBandwidth: ${'ftpULBandwidth'}, ftpDLBandwidth: ${'ftpDLBandwidth'},
    ftpQuotaFiles: ${'ftpQuotaFiles'}, ftpQuotaSize: ${'ftpQuotaSize'},
    ftpIPAccess: "${'ftpIPAccess'}", ftpStatus: ${'ftpStatus'}, defaultDashboard: ${'defaultDashboard'}
	) {
    id
    username
    first_name
    last_name
    email
  }
}`;

const DestroyUserTemplate = template`mutation {
  destroyUser(id: ${'id'}) {
    id
    username
    first_name
    last_name
    email
  }
}`;

const CreateFTPConnectionTemplate = template`mutation {
  createFTPConnection(username: "${'username'}", password: "${'password'}", dir: "${'dir'}") {
    id
    user_id
    username
    password
    dir
    ulbandwidth
    dlbandwidth
    ipaccess
    quotasize
    quotafiles
  }
}`;

const AssignFTPConnectionTemplate = template`mutation {
  assignFTPConnection(connect_id: ${'connectionId'}, user_id: ${'userId'}) {
    id
    username
    first_name
    last_name
    email
  }
}`;

const AssignMeasureTemplate = template`mutation {
  assignMeasure(id: ${'id'}, stationId: ${'stationId'}, stationName: "${'stationName'}", name: "${'name'}", uom: "${'uom'}", conversionFactor: ${'conversionFactor'}, decimals: ${'decimals'}, category: "${'category'}") {
    id
    name
    conversion_factor
    decimals
  }
}`;

const UpdateMeasureTemplate = template`mutation {
  updateMeasure(id: ${'id'}, stationId: ${'stationId'}, name: "${'name'}", uom: "${'uom'}", conversionFactor: ${'conversionFactor'}, decimals: ${'decimals'}, category: "${'category'}") {
    id
    name
    conversion_factor
    decimals
  }
}`;

const AssociateMeasureTemplate = template`mutation {
  associateMeasure(id: ${'id'}, stationId: ${'stationId'}, measureId: ${'measureId'}, association: "${'association'}") {
    id
    name
    conversion_factor
    decimals
  }
}`;

const DestroyMeasureTemplate = template`mutation {
  destroyMeasure(id: ${'id'}, stationId: ${'stationId'}) {
    id
    name
    conversion_factor
    decimals
  }
}`;

const CreateDashboardTemplate = template`mutation {
  createDashboard(name: "${'name'}", description: "${'description'}", is_seeded: ${'isSeeded'}) {
    id
    name
    description
    is_seeded
  }
}`;

const DestroyDashboardTemplate = template`mutation {
  destroyDashboard(id: ${'id'}) {
    id
    name
    description
    is_seeded
  }
}`;

const UpdateDashboardTemplate = template`mutation {
  updateDashboard(id: ${'id'}, name: "${'name'}", description: "${'description'}", is_seeded: ${'isSeeded'}) {
    id
    name
    description
    is_seeded
  }
}`;
    
const AssignRolesTemplate = template`mutation {
  assignRoles(role_ids: ${'roleIds'}, user_id: ${'userId'}) {
    id
    username
	timezone
	locale
	hc_locale
	datetime_format
	numeric_format
	first_name
	last_name
	email
  }
}`;
	
export {
    UserByTokenQueryTemplate,
    UserByTokenQueryParameters,
    UserInfoQueryTemplate,
    UserInfoQueryParameters,
    UserConnectionQueryTemplate,
	UserConnectionQueryParameters,
	RolesByUserIdParameters,
	RolesByUserIdQueryTemplate,
	AssignStationTemplate,
	AssignStationParameters,
	DestroyStationTemplate,
	DestroyStationParameters,
	CreateStationTemplate,
	CreateStationParameters,
    UpdateStationTemplate,
    UpdateStationParameters,
    UpdatePasswordTemplate,
    UpdatePasswordParameters,
	CreateUserTemplate,
	CreateUserParameters,
	DestroyUserTemplate,
	DestroyUserParameters,
	UpdateUserTemplate,
	UpdateUserParameters,
	CreateFTPConnectionTemplate,
	CreateFTPConnectionParameters,
	AssignFTPConnectionTemplate,
	AssignFTPConnectionParameters,
	AssignMeasureTemplate,
	AssignMeasureParameters,
	UpdateMeasureTemplate,
	UpdateMeasureParameters,
	DestroyMeasureTemplate,
	DestroyMeasureParameters,
    AssociateMeasureTemplate,
    AssociateMeasureParameters,
    AssignWidgetTemplate,
    AssignWidgetParameters,
	AssignRolesTemplate,
	AssignRolesParameters,
    UpdateWidgetTemplate,
    UpdateWidgetParameters,
    DestroyWidgetTemplate,
    DestroyWidgetParameters,
    CreateDashboardTemplate,
    CreateDashboardParameters,
    DestroyDashboardTemplate,
    DestroyDashboardParameters,
    UpdateDashboardTemplate,
    UpdateDashboardParameters,
	AllRolesQueryParameters,
	AllRolesQueryTemplate,
    AllUsersQueryTemplate,
    AllUsersQueryParameters,
    AllStationsQueryTemplate,
    AllStationsQueryParameters,
    AllWidgetsQueryTemplate,
    AllWidgetsQueryParameters,
    WidgetsQueryTemplate,
    WidgetsQueryParameters,
    WindDirectionQueryTemplate,
    WindDirectionQueryParameters,
    WindRoseQueryTemplate,
    WindRoseQueryParameters,
    GaugeQueryTemplate,
    GaugeQueryParameters,
    PlotQueryTemplate,
    PlotQueryParameters,
    LastValueQueryTemplate,
    LastValueQueryParameters,
    SeriesQueryTemplate,
    SeriesQueryParameters,
    TableQueryTemplate,
    TableQueryParameters,
    ColumnQueryTemplate,
    ColumnQueryParameters,
    HistogramQueryParameters,
    HistogramQueryTemplate,
	ReportQueryParameters,
	ReportQueryTemplate,
    AllMeasuresCategoryQueryParameters,
    AllMeasuresCategoryQueryTemplate,
    AllMeasuresQueryParameters,
    AllMeasuresQueryTemplate,
    AllDashboardsQueryParameters,
    AllDashboardsQueryTemplate,
    AllCategoriesQueryParameters,
    AllCategoriesQueryTemplate,
    AllOptionsQueryParameters,
    AllOptionsQueryTemplate,
    UsersQueryParameters,
    UsersQueryTemplate,
    CategoriesQueryParameters,
    CategoriesQueryTemplate,
    StationsQueryParameters,
    StationsQueryTemplate,
    StationsByUserIdQueryParameters,
    StationsByUserIdQueryTemplate,
    MeasuresByUserIdQueryParameters,
    MeasuresByUserIdQueryTemplate,
    MeasuresByStationIdQueryParameters,
    MeasuresByStationIdQueryTemplate,
    StationStatusQueryParameters,
    StationStatusQueryTemplate,
    AllStationsStatusQueryParameters,
    AllStationsStatusQueryTemplate,
	BackendStatusQueryParameters,
	BackendStatusQueryTemplate
};
