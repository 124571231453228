import * as config from '../data/config.json';

import { useDevServerUrl } from './auth-service';

//export const apiServerBaseUrl = "http://localhost:8080";
export const apiServerBaseUrl = (useDevServerUrl ? config.apiServerBaseUrl : "/index.php");

//export const configImportServiceUrl = "/index.php/station/config";

//export const bulkExportServiceUrl = "/index.php/station/{id}/export";

//export const apiServerAuthUrl = "//localhost:8080/index.php/authorize";
export const apiServerAuthUrl = config.apiServerAuthUrl;

//export const apiServerAuthClientId = "testclient";
export const apiServerAuthClientId = config.apiServerAuthClientId;

//export const apiServerAuthClientGrantType = "password";
export const apiServerAuthClientGrantType = config.apiServerAuthClientGrantType;

export const configImportServiceUrl = "/station/config";

export const bulkExportServiceUrl = apiServerBaseUrl.concat("/station/{id}/export");

//export const apiServerClientId = "testclient";
export const apiServerClientId = config.apiServerClientId;

//export const apiServerClientSecret = "";
export const apiServerClientSecret = config.apiServerClientSecret;

//export const apiServerUrlSuffix = "/api/graphql";
export const apiServerUrlSuffix = config.apiServerUrlSuffix;

export const ftpServerDomain = "ftp.alifa.cloud";
export const ftpServerAddress = config.ftpServerAddress;
export const ftpServerPort = config.ftpServerPort;

export const dashboardColorScheme = ["#597ef7", "#bae637", "#82e8df"];
export const dashboardYTitleColor = "#333";
export const dashboardXLabelsColor = "#333";
export const dashboardYLabelsColor = "#333";
export const dashboardTitleColor = "#fff";
