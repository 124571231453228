import axios from "axios";

import  { apiServerClientId, apiServerClientSecret, apiServerBaseUrl } from "./config";

import { setExpiresIn, setAccessToken, setRefreshToken } from "./auth-service";

let instance = null;

const default_expiresIn = 3600;

let DateNow = () => { return + new Date(); };

class TokenService {
    constructor() {
        if (!instance) {
            this.accessToken = null;
            this.refreshToken = null;
            this.expiresIn = default_expiresIn;
            this._expiresIn = null;

            instance = this;
        }

        return instance;
    }

    hasValidToken() {
        if (!isNaN(this._expiresIn) && !isNaN(this.expiresIn)) {
            return this._expiresIn + this.expiresIn > DateNow();
        }
        return false;
    }

    setExpiresIn(expiresIn) {
        setExpiresIn(this.expiresIn = expiresIn);
    }

    setAccessToken(accessToken) {
        setAccessToken(this.accessToken = accessToken);
        this._expiresIn = DateNow();
    }

    setRefreshToken(refreshToken) {
        setRefreshToken(this.refreshToken = refreshToken);
    }

    getRefreshIn() {
        return this.refreshIn;
    }

    getAccessToken() {
        return this.accessToken;
    }

    getExpiresIn() {
        return this.expiresIn;
    }

    getRefreshToken() {
        return this.refreshToken;
    }

    async grantAccessToken(username, password, grantType = "password") {
        let clientId = apiServerClientId;
        let clientSecret = apiServerClientSecret;

        let payload = {
            "grant_type": grantType,
            "client_id": clientId,
            "username": username,
            "password": password
        };

        if (clientSecret) payload["client_secret"] = clientSecret;

        let axiosPost = axios.post(apiServerBaseUrl + "/authorize", payload);
        return axiosPost;
    }

    async refreshAccessToken(refreshToken, grantType = "refresh_token") {
        let clientId = apiServerClientId;
        let clientSecret = apiServerClientSecret;

        let payload = {
            "grant_type": "refresh_token",
            "client_id": clientId,
            "refresh_token": refreshToken
        };

        if (clientSecret) payload["client_secret"] = clientSecret;

        let axiosPost = axios.post(apiServerBaseUrl + "/token", payload);

        axiosPost.then((result) => {
            const { access_token, refresh_token } = result;
            console.log("resfresh_token reply", result);
            return new Promise((resolve, reject) => { // ...
                resolve({accessToken: access_token, refreshToken: refresh_token});
            });
        });

        return axiosPost;

        //new Promise((resolve, reject) => axios.get(url).then(res => resolve(res)).catch(err => resolve(err)) )
    }

    base64EncodeUnicode(str) {
        // First we escape the string using encodeURIComponent to get the UTF-8 encoding of the characters, 
        // then we convert the percent encodings into raw bytes, and finally feed it to btoa() function.
       let utf8Bytes = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode('0x' + p1);
       });

       return btoa(utf8Bytes);
    }

}

export default TokenService;
