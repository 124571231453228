import axios from "axios";

import TokenService from "./token-service";

import { apiServerBaseUrl } from "./config";

import { logout, setAccessToken, getAccessToken, setRefreshToken, getRefreshToken } from "./auth-service";

class Request {
    constructor(headers = null) {
        this.isRefreshing = false;
        this.failedRequests = [];
        this.tokenService = new TokenService();

        this.tokenService.setAccessToken(getAccessToken()); // assume a login is already accomplished

        this.clientConfig = {
            baseURL: apiServerBaseUrl
        };
        this.client = axios.create(this.clientConfig);
            /*,
            headers: {
                client_secret: config.apiServerClientSecret,
                client_id: config.apiServerClientId
            }*/
        this.beforeRequest = this.beforeRequest.bind(this);
        this.onRequestFailure = this.onRequestFailure.bind(this);
        this.processQueue = this.processQueue.bind(this);
        this.client.interceptors.request.use(this.beforeRequest);
        this.client.interceptors.response.use(this.onRequestSuccess, this.onRequestFailure);
    }

    beforeRequest(request) {
        const token = this.tokenService.getAccessToken();
        //request.headers.Authorization = `Token ${token}`;
        request.headers.Authorization = `Bearer ${token}`;
        return request;
    }

    static onRequestSuccess(response) {
        return response.data;
    }

    async onRequestFailure(err) {
        const { response } = err;
        if ((response && response.status === 401) && (err && err.config && !err.config.__isRetryRequest)) {
            if (this.isRefreshing) {
                try {
                    const token = await new Promise((resolve, reject) => {
                        this.failedRequests.push({ resolve, reject });
                    });
                    err.config.headers.Authorization = `Bearer ${token}`;
                    return this.client(err.config);
                }
                catch (e) {
                    return e;
                }
            }
            this.isRefreshing = true;
            err.config.__isRetryRequest = true;
            return new Promise((resolve, reject) => {
                this.tokenService.refreshAccessToken(getRefreshToken()).then((tokens) => {
                    console.log("refreshAccessToken", tokens);
                    if (tokens.status === 200 && !tokens.data) {
                      return logout(() => console.warn && console.warn("Forcing user logout on missing server auth data"));
                    }
                    const token = tokens.data.access_token;
                    const refreshToken = tokens.data.refresh_token;
                    this.tokenService.setAccessToken(token);
                    setAccessToken(token);
                    setRefreshToken(refreshToken);
                    err.config.headers.Authorization = `Bearer ${token}`;
                    this.isRefreshing = false;
                    this.processQueue(null, token);
                    resolve(this.client(err.config));
                }).catch((e) => {
                    console.log("request-client", e);
                    this.processQueue(e, null);
                    reject(err.response);
                });
            });
        }
        throw response;
    }

    processQueue(error, token = null) {
        this.failedRequests.forEach((promise) => {
            if (error) {
                promise.reject(error);
            } else {
                promise.resolve(token);
            }
        });
        this.failedRequests = [];
    }
}

const request = new Request();

export default request.client;
